type Env = {
  /** The API endpoint of the backend server */
  API_URL: string;

  /** Whether this is an end-to-end integration test. */
  IS_E2E: boolean;
  /** Environment for sentry logging. 'local' means indicates not to log to sentry. */
  SENTRY_ENV: 'local' | 'development' | 'developmentSim' | 'production';
};

/**
 * Defaults should make sense for a development environment.
 */
const ENV_DEFAULTS: Env = {
  API_URL: 'https://preprod.api.whiteroseeducation.com',
  IS_E2E: false,
  SENTRY_ENV: 'development'
};

/** Environment variables only count as true if they're the true boolean or the string 'true'. */
const isTrue = (x: unknown) => x === true || x === 'true';

/**
 * Variables picked up from environment variables. Used to configure how the app works, e.g. set endpoint URLs or set
 * feature switches.
 *
 * To change these, set environment variables in expo (eas.json) or other tools (e.g. cypress for integration testing).
 *
 * Note that for the boolean values, only the true boolean or the string 'true' counts as true.
 *
 * Note: Right now on web (either with `yarn expo start --web` or `yarn export:web`) environment variables aren't
 * being picked up, so you'll have to hack it before doing a build.
 */
const ENV: Env = { ...ENV_DEFAULTS };

// Pick up environment variables that were set via process env, usually picked up from eas.json.
// Overrides the defaults.
// NOTE: currently doesn't work for web builds.
if (process.env.EXPO_PUBLIC_API_URL !== undefined) ENV.API_URL = process.env.EXPO_PUBLIC_API_URL;
if (process.env.EXPO_PUBLIC_IS_E2E !== undefined)
  ENV.IS_E2E = isTrue(process.env.EXPO_PUBLIC_IS_E2E);
if (process.env.EXPO_PUBLIC_ENV !== undefined)
  ENV.SENTRY_ENV = process.env.EXPO_PUBLIC_ENV as (typeof ENV)['SENTRY_ENV']; // Cast without checking

// HACK: Add overrides here for web builds, but never commit them!
// ENV.API_URL = 'https://api.whiteroseeducation.com'
// ENV.IS_E2E = false;
// ENV.SENTRY_ENV = 'production'

// Pick up environment variables that were set via cypress. Overrides the above.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Cypress = window?.Cypress;
if (Cypress?.env('EXPO_PUBLIC_IS_E2E') !== undefined)
  ENV.IS_E2E = isTrue(Cypress.env('EXPO_PUBLIC_IS_E2E'));

export default ENV;
