import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [8, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UnderstandAnglesAsTurns',
      questionTypes: ['aya', 'ayb', 'ayc', 'ayd', 'aye', 'ayf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Shape/1UnderstandAnglesAsTurns" */ './1UnderstandAnglesAsTurns'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'IdentifyAngles',
      questionTypes: ['ayg', 'ayh', 'ayi', 'ayj', 'ayk', 'ayl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Shape/2IdentifyAngles" */ './2IdentifyAngles'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CompareAndOrderAngles',
      questionTypes: ['aym', 'ayn2', 'ayo', 'ayp', 'ayq2', 'ayr2'],
      archivedQuestionTypes: ['ayn', 'ayq', 'ayr'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Shape/3CompareAndOrderAngles" */ './3CompareAndOrderAngles'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Triangles',
      questionTypes: ['ays', 'ayt', 'ayu', 'ayv', 'ayw', 'ayx2'],
      archivedQuestionTypes: ['ayx'],
      module: () =>
        import(/* webpackChunkName: "Year4/Summer/Shape/4Triangles" */ './4Triangles').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'Quadrilaterals',
      questionTypes: ['ayy', 'ayz', 'ayA', 'ayB', 'ayC', 'ayD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Shape/5Quadrilaterals" */ './5Quadrilaterals'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'Polygons',
      questionTypes: ['ayE', 'ayF2', 'ayG', 'ayH', 'ayI', 'ayJ'],
      archivedQuestionTypes: ['ayF'],
      module: () =>
        import(/* webpackChunkName: "Year4/Summer/Shape/6Polygons" */ './6Polygons').then(
          mod => mod.default
        )
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'LinesOfSymmetry',
      questionTypes: ['ayK', 'ayL', 'ayM', 'ayN', 'ayO', 'ayP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Shape/7LinesOfSymmetry" */ './7LinesOfSymmetry'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'CompleteASymmetricFigure',
      questionTypes: ['ayQ', 'ayR', 'ayS', 'ayT', 'ayU', 'ayV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Shape/8CompleteASymmetricFigure" */ './8CompleteASymmetricFigure'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
